import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styled from "styled-components"
import colors from "../colors"
import Title from "./Title"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Inner = styled.div`
  flex: 0 1;
  display: flex;
  flex-direction: row;
`

const Form = styled.form`
  flex: 1 1;
  width: 100%;
  position: relative;
  h5 {
    width: 100%;
    text-align: center;
    font-family: "Bowlby One SC";
    font-size: 24px;
    color: ${colors.white};
    text-shadow: 1px 1px ${colors.black};
    margin: 10px 0;
  }
  input,
  button {
    display: block;
    width: 100%;
    @media (min-width: 420px) {
      display: inline-block;
      width: auto;
    }
    box-sizing: border-box;
    padding: 10px 15px;
    font-size: 20px;
    -webkit-appearance: none;
    border-radius: 6px;
  }
  input {
    border: 3px solid ${colors.cyan};
    color: ${colors.cyan};
    margin: 10px 0;
  }
  button {
    background-color: ${colors.cyan};
    color: ${colors.white};
    border: 3px solid ${colors.white};
    font-family: "Bowlby One SC";
    margin: 0;
    @media (min-width: 420px) {
      margin: 0 5px;
    }
    text-shadow: 1px 1px ${colors.black};
  }
`

const Success = styled.div`
  font-size: 20px;
  font-family: "Bowlby One SC";
  text-shadow: 1px 1px ${colors.black};
  color: ${colors.yellow};
`

const SignUpForm = () => {
  const [subscribed, setSubscribed] = useState(false)
  const [email, setEmail] = useState("")
  const handleSubmit = async e => {
    e.preventDefault()

    await addToMailchimp(email, null, null)
    setSubscribed(true)
  }

  return (
    <Wrapper>
      <Title color={colors.cyan} shadowColor={colors.white} headroom>
        Sign Up
      </Title>
      <Inner>
        {!subscribed ? (
          <Form onSubmit={handleSubmit}>
            <h5>For The Wig Out Email List</h5>
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            ></input>
            <button>Sign up!</button>
          </Form>
        ) : (
          <Success>Thanks for subscribing!</Success>
        )}
      </Inner>
    </Wrapper>
  )
}

export default SignUpForm
