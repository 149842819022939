import React from "react"
import { Album } from "../data/albums/types"

import colors from "../colors"
import Layout from "../components/Layout"
import AlbumCover from "../components/AlbumCover"
import SEO from "../components/seo"
import Title from "../components/Title"
import styled from "styled-components"
import SignUpForm from "../components/SignUp"
import { getAlbum } from "../data/albums"
import AlbumText from "../components/AlbumText"

const OutLink = styled.a`
  font-family: "Bowlby One SC";
  font-size: 30px;
  color: white;
  text-decoration: none;
  &:hover {
    color: ${colors.yellow};
  }
`

const AlbumLinks = ({ album }: { album: Album }) => (
  <>
    {album.links.map(link => (
      <OutLink key={link.description} href={link.url}>
        {link.description}
      </OutLink>
    ))}
  </>
)

const Stripe = styled.div<{ count: number; index: number; color: string }>`
  width: ${props => 100 / props.count}%;
  top: 0;
  bottom: 0;
  background-color: ${props => props.color};
  position: absolute;
  left: ${props => (100 / props.count) * props.index}%;
`

const StripeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const StripeLayoutWrapper = styled.div`
  position: relative;
`

const VerticalStripes = (props: { colors: string[] }) => {
  const count = props.colors.length
  return (
    <StripeWrapper>
      {props.colors.map((c, i) => (
        <Stripe key={i} index={i} count={count} color={c} />
      ))}
    </StripeWrapper>
  )
}

const VerticalStripesLayout: React.FC<{ colors: string[] }> = props => (
  <StripeLayoutWrapper>
    <VerticalStripes colors={props.colors} />
    <StripeLayoutWrapper>{props.children}</StripeLayoutWrapper>
  </StripeLayoutWrapper>
)

const bakedDwarves = getAlbum("baked-dwarves")
const numberTwo = getAlbum("number-two")
const problem = getAlbum("we-have-a-problem")
const essentialTrips = getAlbum("essential-trips")
const quintessence = getAlbum("quintessence")
const mobius = getAlbum("mobius-ship")
const getsEven = getAlbum("gets-even")
const kyjas = getAlbum("kyjas")

const YoutubeEmbed = ({ embedId }: { embedId: string }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
)

export default () => (
  <>
    <SEO title="The Wig Out" />

    <Layout textColor={colors.white} backgroundColor={colors.black}>
      <Title mega color={colors.white} shadowColor={colors.pink}>
        THE WIG OUT
      </Title>
      <Title color={colors.pink} shadowColor={colors.kyjas.greenish}>
        New Video!
      </Title>
      <YoutubeEmbed embedId="DJHWPg2Wkmc" />
      <AlbumText color={colors.white}>
        Video for the upcoming single Curvature! Watch it! Now!
      </AlbumText>
    </Layout>

    <Layout textColor={colors.black} backgroundColor={colors.white}>
      <Title color={colors.kyjas.greenish} shadowColor={colors.kyjas.orangeish}>
        Singles/remixes/etc!
      </Title>
      <AlbumText>
        We have a few new singles out -- these are
        remixes/rethinkings/revisitations of songs we released last year.
      </AlbumText>
      <AlbumText>Go to your favorite streming service to find them.</AlbumText>
      <AlbumText></AlbumText>
    </Layout>

    <Layout textColor={colors.white} backgroundColor={colors.black}>
      <Title
        color={colors.bakedDwarves.cyan}
        shadowColor={colors.bakedDwarves.yellow}
      >
        Live Video: Chicago Avenue Live!
      </Title>
      <YoutubeEmbed embedId="D3W-j9wQq58" />
      <AlbumText color={colors.white}>
        People playing music together in a room like it's 2019! This is a live
        version of Chicago Ave and East 38th Street, from our album
        Quintessence.
      </AlbumText>
    </Layout>

    <Layout textColor={colors.black} backgroundColor={colors.white}>
      <Title color={colors.kyjas.greenish} shadowColor={colors.kyjas.orangeish}>
        Keep Your Joy A Secret
      </Title>
      <AlbumCover filename="kyjas.png" borderColor={colors.kyjas.greenish} />
      <AlbumText></AlbumText>
      <Title color={colors.kyjas.orangeish} shadowColor={colors.kyjas.greenish}>
        Listen!
      </Title>
      <AlbumLinks album={kyjas} />
    </Layout>
    <VerticalStripesLayout
      colors={[
        colors.getsEven.red,
        colors.getsEven.green,
        colors.getsEven.cyan,
        colors.getsEven.yellow,
        colors.getsEven.pink,
        colors.getsEven.magenta,
      ]}
    >
      <Layout textColor={colors.black} backgroundColor={colors.transparent}>
        <Title color={colors.black} shadowColor={colors.pink}>
          Gets Even
        </Title>
        <AlbumCover filename="gets-even.png" borderColor={colors.black} />
        <AlbumText>
          Our last album of the longest year in recorded human history! ART BY
          GOLDSUIT! Seven Songs! GET THIS SHIT IN YOUR TAPEDECK NOW!
        </AlbumText>
        <Title headroom></Title>
        <Title color={colors.black} shadowColor={colors.problem.pink}>
          Listen
        </Title>
        <AlbumLinks album={getsEven} />
      </Layout>
    </VerticalStripesLayout>

    <Layout textColor={colors.black} backgroundColor={colors.white}>
      <Title color={colors.black} shadowColor={colors.pink}>
        Möbius Ship
      </Title>
      <AlbumCover filename="mobius-ship.png" borderColor={colors.black} />
      <AlbumText>
        Our sixth album is an endlessly looping 45-minute audio adventure,
        written, recorded, and mixed by 14 musicians between July 17th and
        August 3rd, 2020.
      </AlbumText>
      <Title headroom></Title>
      <Title color={colors.black} shadowColor={colors.problem.pink}>
        Listen
      </Title>
      <AlbumLinks album={mobius} />
    </Layout>
    <Layout
      textColor={colors.black}
      backgroundColor={colors.quintessence.yellow}
    >
      <Title color={colors.black} shadowColor={colors.pink}>
        Quintessence
      </Title>
      <AlbumCover filename="quintessence.png" borderColor={colors.black} />
      <Title color={colors.black} shadowColor={colors.problem.pink}></Title>
      <AlbumText>
        Our fifth album is our best yet, or at least one of the most recent.
      </AlbumText>
      <Title color={colors.black} shadowColor={colors.problem.pink}>
        Listen
      </Title>
      <AlbumLinks album={quintessence} />
      <Title headroom></Title>
    </Layout>
    <Layout textColor={colors.white} backgroundColor={colors.black}>
      <Title color={colors.white} shadowColor={colors.problem.pink}>
        Essential Trips
      </Title>
      <AlbumCover filename="essential-trips.png" borderColor={colors.white} />
      <AlbumText>
        Our fourth album touches on themes as varied as rock and roll.
      </AlbumText>
      <Title color={colors.white} shadowColor={colors.problem.pink}>
        Listen
      </Title>
      <AlbumLinks album={essentialTrips} />
      <Title headroom></Title>
    </Layout>
    <Layout
      textColor={colors.white}
      backgroundColor={colors.problem.darkPurple}
    >
      <Title color={colors.white} shadowColor={colors.problem.pink}>
        We Have A Problem
      </Title>
      <AlbumCover filename="problem.png" borderColor={colors.white} />
      <AlbumText>Our third album. An absolute unit.</AlbumText>
      <Title color={colors.problem.pink} shadowColor={colors.white}>
        Listen
      </Title>
      <AlbumLinks album={problem} />

      <Title headroom></Title>
    </Layout>

    <Layout backgroundColor={colors.white} textColor={colors.black}>
      <Title color={colors.numberTwo.orange} shadowColor={colors.black}>
        Number Two
      </Title>
      <AlbumCover filename="number-two.png" borderColor={colors.black} />
      <AlbumText>A sophomore album with a sophomoric title</AlbumText>
      <AlbumLinks album={numberTwo} />

      <Title headroom></Title>
    </Layout>

    <Layout backgroundColor={colors.pink} textColor={colors.white}>
      <Title color={colors.yellow} shadowColor={colors.black}>
        Baked Dwarves Moan At Harvestman
      </Title>
      <AlbumCover filename="transparent-logo.png" />
      <AlbumText>Our debut album, released way back in May 2020.</AlbumText>
      <AlbumText>
        It feels like we've lived three-and-a-half lifetimes since then.
      </AlbumText>

      <AlbumLinks album={bakedDwarves} />

      <SignUpForm />
    </Layout>
  </>
)
